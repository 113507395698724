import React, {Fragment, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory, useLocation} from 'react-router-dom';
import cn from 'classnames';
import { toast } from 'react-toastify';

import AdSense from 'components/AdSense';
import Breadcrumb from 'components/Breadcrumb';
import Select from 'components/Common/Select';
import Table, {Row} from 'components/Common/Table';
import Confirm from 'components/Common/Confirm';

import * as api from 'api';
import * as types from 'store/db/types';
import * as utils from 'utils';

import styles from './Groups.module.scss';
import AddGroup from 'components/Competition/AddGroup';

interface LocationState {
  competition: types.Competition;
}

const Groups = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const locationState = location.state as LocationState;

  const [registrations, setRegistrations] = useState<types.Registration[]>([]);
  const [competitionTypes, setCompetitionTypes] = useState<types.CompetitionType[]>([]);
  const [groups, setGroups] = useState<types.Group[]>([]);
  const [selectedCompetitionType, setSelectedCompetitionType] = useState<types.CompetitionType>();
  const [selectedRegistration, setSelectedRegistration] = useState<types.Registration>();
  const [selectedGroupRegistration, setSelectedGroupRegistration] = useState<types.Registration>();
  const [selectedGroupId, setSelectedGroupId] = useState<number>();
  const [selectedGroupToDelete, setSelectedGroupToDelete] = useState<types.Group>();
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [showAddGroup, setShowAddGroup] = useState<boolean>(false);
  const [updated, setUpdated] = useState<number>(0);

  useEffect(() => {
    (async () => {
      if (locationState && registrations.length === 0) {
          const [competitionTypes, regs, groups] = await Promise.all([
            api.get(`competitions/${locationState.competition.id}/types`),
            api.get(`registrations/all/${locationState.competition.id}`),
            api.get(`groups/${locationState.competition.id}`)
          ]);
          setCompetitionTypes(competitionTypes);
          setRegistrations(regs);
          setGroups(groups);
          if (!selectedCompetitionType && competitionTypes.length > 0) {
            setSelectedCompetitionType(competitionTypes[0]);
          }
      }
    })();
  }, [locationState, registrations.length, selectedCompetitionType]);

  const moveToGroup = async (group: types.Group) => {
    const registration = selectedRegistration;
    registration!.group_id = group.id;
    const saved = await api.update(t, 'registrations', registration!);
    updateRegistrations(saved, registration!);
};

  const moveFromGroup = async () => {
    const matches = await api.get(`matches/${locationState.competition.id}/registration/${selectedGroupRegistration!.id}`);
    if (matches.length > 0) {
      toast(t('WARNING_MOVE_REGISTRATION_HAS_MATCHES') as string, {type: toast.TYPE.WARNING});
    } else {
      const registration = selectedGroupRegistration;
      registration!.group_id = null;
      const saved = await api.update(t, 'registrations', registration!);
      updateRegistrations(saved, registration!);
    }
  };

  const updateRegistrations = (saved: boolean, registration: types.Registration) => {
    if (saved) {
      const regs = registrations;
      const idx = regs.findIndex(item => item.id === registration?.id);
      regs[idx] = registration!;
      setRegistrations(regs);
      setSelectedRegistration(undefined);
      setSelectedGroupRegistration(undefined);
      setUpdated(updated + 1);
    }
  };

  const deleteGroup = async () => {
    const deleted = await api.remove(t, `groups/${selectedGroupToDelete?.id}`);
    if (deleted) {
      const updatedGroups = groups.filter(item => item.id !== selectedGroupToDelete?.id);
      setGroups(updatedGroups);
      setShowConfirm(false);
    }
  }

  const renderRegistrations = () => {
    if (!selectedCompetitionType) {
      return null;
    }

    const rows: Row[] = registrations
        .filter(reg => reg.competition_type.id === selectedCompetitionType.id && reg.group_id === null)
        .sort((a, b) => utils.orderRegistration(t, locationState.competition.sport, a, b))
        .map(registration => {
          const columns: string[] = [];
          columns.push(utils.getNameWithLevel(t, locationState.competition.sport, registration));

          return { item: registration, columns, icons: [] };
        });

    return (
      <Table
        header={[t('REGISTRATIONS') as string]}
        rows={rows}
        isRowSelectable
        onChangeSelectedRow={(row) => setSelectedRegistration(row?.item)}
      />);
  };

  const renderGroup = (groupId: number) => {

    const rows: Row[] = registrations
        .filter(reg => reg.group_id === groupId)
        .map(registration => {
          const columns: string[] = [];
          columns.push(utils.getName(t, registration));

          return { item: registration, columns, icons: [] };
        });

    return (
      <Table
        header={[]}
        rows={rows}
        isRowSelectable
        onChangeSelectedRow={(row) => {
          setSelectedGroupRegistration(row?.item);
          setSelectedGroupId(row ? groupId : undefined);
        }}
      />);
  };

  const renderGroups = () => {
    return (
      <div className={cn(styles.container, styles.groups)}>
        {groups.filter(item => item.competition_type.id === selectedCompetitionType?.id)
            .sort((a, b) => utils.sortCompetitionTypes(t, a.competition_type, b.competition_type))
            .map(item => {
              const countRegistrations = registrations.filter(reg => reg.group_id === item.id).length;
              const hasRegistrations = countRegistrations > 0;
              return (
                <Fragment key={item.id}>
                  <div>
                    <input type="button" value=">" disabled={!selectedRegistration} onClick={() => moveToGroup(item)} />
                    <input type="button" value="<" disabled={!selectedGroupId} onClick={moveFromGroup} />
                  </div>
                  <div>
                    <div className={styles.groupHeader}>
                      <i
                        className={`far fa-trash-alt tooltip ${hasRegistrations ? 'disabled' : ''}`}
                        style={{float: 'right'}}
                        onClick={() => { setSelectedGroupToDelete(item); setShowConfirm(true); }}
                      >
                        {hasRegistrations && <div>{t('GROUP_HAS_REGISTRATIONS') as string}</div>}
                      </i>
                      {`${t(types.CompetitionTypeName[item.competition_type.name])} ${item.name}`} ({t('GROUP_REGISTRATIONS', {total: countRegistrations}) as string})
                    </div>
                    {renderGroup(item.id)}
                    {types.isCompetitionTypeWithVaryingPartner(selectedCompetitionType?.name) && countRegistrations !== 8
                        && <div className="errorText">{t('VALIDATION_VARYING_PARTNER_GROUP_NOT_8') as string}</div>}
                  </div>
                </Fragment>
              );
            })}
      </div>
    );
  }

  return (
      <div>
        <div>
          <Breadcrumb competition={locationState?.competition} title={'MANAGE_GROUPS_TITLE'} />
          <p dangerouslySetInnerHTML={{__html: t('MANAGE_GROUPS_TEXT') as string}} />
          <input
            type="button"
            value={'' + t('ADD_GROUP') as string}
            className="button"
            disabled={!selectedCompetitionType}
            onClick={() => setShowAddGroup(true)}
          />
          <input
              type="button"
              value={'' + t('BACK') as string}
              className="button"
              onClick={() => history.goBack()}
          />
          <div className="filter">
            <Select
                placeholder={t('SELECT_COMPETITION_TYPE') as string}
                canSelectAll={false}
                defaultValue={selectedCompetitionType
                  ? `${t(types.CompetitionTypeName[selectedCompetitionType.name])}` : undefined}
                options={competitionTypes.map((type: types.CompetitionType) => {
                  const key = type;
                  const value = `${t(types.CompetitionTypeName[type.name])}`;
                  return {key, value};
                })}
                onChange={(option: types.CompetitionType) => setSelectedCompetitionType(option)} />
          </div>
          {selectedCompetitionType && <p>{t('TOTAL_REGISTRATIONS', { total:
            registrations.filter(reg => reg.competition_type.id === selectedCompetitionType.id && reg.group_id === null).length}) as string}</p>}
          <div className={styles.twoColumns}>
            <div className={styles.container}>
              {renderRegistrations()}
            </div>
            {renderGroups()}
          </div>
        </div>
        {showAddGroup && selectedCompetitionType && <AddGroup
            competitionType={selectedCompetitionType}
            onClose={() => setShowAddGroup(false)}
            onSave={(group) => {
              const grps = groups;
              grps.push(group);
              setGroups(grps);
              setShowAddGroup(false);
            }}
          />}
        {showConfirm && selectedGroupToDelete && <Confirm
          title={t('DELETE_GROUP_TITLE') as string}
          message={t('DELETE_GROUP_TEXT', {"group": selectedGroupToDelete.name}) as string}
          onNo={() => { setShowConfirm(false); setSelectedGroupToDelete(undefined); }}
          onYes={deleteGroup}
        />}
        <AdSense client="ca-pub-6405877826988355" slot="3631385627" format="auto" style={{display:"block"}} responsive="true" />
      </div>
  );
}

export default Groups;